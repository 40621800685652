import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ArticleCard from '../components/ArticleCard';

const IndexPage = ({ data }) => {
	return (
		<Layout>
			<Seo
				title="About beachwheelers.com"
				description="beechwheelers.com where you can find all information about beach carts and wagons."
			/>
			<div className="o-wrapper">
				<div className="o-row-3 o-row-6-d">
					<div className="c-index-hero">
						<h1 className="c-index-hero__title">
							<mark>About</mark>
							<br />
							<mark className="c-index-hero__title-sm">beachwheelers.com</mark>
						</h1>
						<GatsbyImage image={getImage(data.file.childImageSharp)} alt="beach cart in the sand" />
					</div>
				</div>
				<div className="o-row-3 o-row-6-d">
					<p className="c-md-p">
						Beachwheelers.com is the place to go for people who are enthusiastic about beach wagons. Our mission is to
						help you research and find the best beach carts for you. We believe in honesty, and we want to give you an
						honest look at the best beach carts out there.
					</p>
					<p className="c-md-p">
						We believe that everyone deserves a chance to enjoy their time on the beach, so we'll do everything we can
						to help you find what works best for you.
					</p>
					<p className="c-md-p">We hope this website helps you have an amazing time on your next vacation!</p>
					<p className="c-md-p">
						We know how hard it can be to find the right beach wagon: there are so many options out there, and each one
						has its own set of positives and negatives. That's why we don't just tell you what we think about a
						product—we give you all the information you need to make your own decision. You'll find detailed
						descriptions of each model, along with photos and videos that show them in action. We also have side-by-side
						comparisons that let you see how different models stack up against each other.
					</p>
					<p className="c-md-p">
						At Beachwheelers.com, we believe in giving people the tools they need to make smart decisions about their
						purchases—and that starts by making sure they have all the facts about any product they're considering
						buying!
					</p>
				</div>
				<div className="o-row-3 o-row-6-d">
					<h2 className="c-md-h2">Read some of our reviews below</h2>
				</div>
				<div className="o-row-3 o-row-6-d">
					<div className="c-article-card-wrapper">
						{data.allMdx.nodes.map((post, i) => (
							<ArticleCard
								key={i}
								url={post.slug}
								img={post.frontmatter.featuredImage}
								title={post.frontmatter.headline}
								description={post.excerpt}
							/>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query {
		file(name: { eq: "background-jumbo" }) {
			childImageSharp {
				gatsbyImageData(width: 890, placeholder: BLURRED, formats: AUTO)
			}
		}
		allMdx(limit: 4, filter: { frontmatter: { tags: { in: "review" } } }) {
			nodes {
				excerpt(pruneLength: 400)
				frontmatter {
					featuredImage {
						childImageSharp {
							gatsbyImageData(width: 240, placeholder: BLURRED, formats: AUTO)
						}
					}
					headline
				}
				slug
			}
		}
	}
`;
